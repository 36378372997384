<template>
  <div class="demo">
    <div id="checkInType" class="chartBar" ></div>
    <!-- :class="{echartNoData:!hasFlag&&!showCharts}" -->
  </div>
</template>
<script>
import CountTo from "vue-count-to";
import { GET_checkInRoomType } from "@/api";
import {
  title_Font,
  title2_Font,
  x_Font,
  y_Font,
  w_Font,
  h_Font,
  line_h_Font,
  line_Font,
} from "./font";

export default {
  components: {
    CountTo,
  },
  data() {
    return {
      showCharts:false,
      roomType: ["高级大床房", "高级双床房", "舒适大床房", "豪华大床房", "欢朋套房"],
      roomTypeData: [
        {
          name: "高级大床房",
          value: 312,
        },
        {
          name: "高级双床房",
          value: 512,
        },
        {
          name: "舒适大床房",
          value: 353,
        },
        {
          name: "豪华大床房",
          value: 222,
        },
        {
          name: "欢朋套房",
          value: 126,
        },
      ],
      hasFlag: true,
    };
  },
  mounted() {
    this.checkInType = this.$echarts.init(
      document.getElementById("checkInType")
    );
    window.addEventListener("resize", () => {
      this.checkInType.resize();
    });
    this.getRoomType();
    this.InTypeInter = null;
    this.InTypeInter = setInterval(() => {
      this.getRoomType();
    }, 3600000);
  },
  computed: {
    color() {
      return this.$store.state.color;
    },
  },
  beforeDestroy() {
    clearInterval(this.InTypeInter);
  },
  methods: {
    getOptions(data) {
      return {
        title: {
          text: "入住房型",
          textStyle: {
            align: "left", //字体居中
            color: "#fff", //字体颜色
            fontSize: title2_Font(), //字体大小
          },
        },
        tooltip: {
          trigger: "item",
          textStyle: {
            color: "#fff",
            lineHeight: h_Font(),
            fontSize: x_Font(),
          },
          formatter: "{b} : </br>{c} ({d}%)",
        },
        color: this.color,
        legend: {
          bottom: x_Font(),
          itemWidth: w_Font(),
          itemHeight: h_Font(),
          textStyle: {
            color: "#fff",
            lineHeight: line_h_Font(),
            fontSize: x_Font(),
          },
          data: this.roomType,
        },
        series: [
          {
            name: "入住房型",
            type: "pie",
            radius: ["25%", "38%"],
            center: ["50%", "45%"],
            data: this.roomTypeData,
            label: {
              fontSize: x_Font(),
            },
            labelLine: {
              length: h_Font(),
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                fontSize: x_Font(),
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
    },
    // 获取七日内的pms 出租率情况
    async getRoomType(data = {}) {
      try {
        this.hasFlag = true;
        let res = await GET_checkInRoomType(data);
        if (res.data.code == "000000") {
          if (!res.data.data || res.data.data.length == 0) {
            // this.hasFlag = false;
            // this.showCharts=true;
          } else {
            // this.showCharts=true;
            // this.roomType = [];
            // this.roomTypeData = [];
            // res.data.data.forEach((v) => {
            //   this.roomType.push(v.roomTypeName);
            //   this.roomTypeData.push({
            //     name: v.roomTypeName,
            //     value: v.value,
            //   });
            // });
          }
        } else {
          // this.hasFlag=false
        }
      } catch (error) {
        //  this.hasFlag=false
      }
      if (this.hasFlag) {
        this.initLine();
      }
    },
    initLine() {
      this.checkInType.setOption(this.getOptions(this.sevenPms));
    },
    resize() {
      this.checkInType.resize();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/mdKanban/index.scss";
.demo {
  width: 100%;
  height: 100%;
}
</style>